import * as React from "react";
import LogoImage from "../images/logo.png";
// import WelcomeImage from "../images/welcome.png";
import MapImage from "../images/karta.png";
import ClinicImage from "../images/clinic.png";
import ModeImage1 from "../images/IMG_1083.jpg";
// import ModeImage2 from "../images/IMG_1087.jpg";
// import ModeImage3 from "../images/IMG_1094.jpg";

import "../styles/index.css";

const pageStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  color: "#000",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  minHeight: '100%',
}

const header = {
  display: 'flex',
  flexShrink: 0,
  justifyContent: 'center',
  padding: "50px 15px 70px",
  backgroundColor: "#192537",
  textAlign: 'center',
  width: '100%',
  margin: '0 0 20px',
}

const logo = {
  maxWidth: '40%',
}

const profileImage = {
  maxWidth: '100%',
}

const mapStyles = {
  margin: '0 auto',
}

const bookingStyle = {
  marginTop: '30px',
  textAlign: 'center'
}

const h1Style = {
  fontSize: '24px',
  fontWeight: 'bold',
  margin: '20px 0 30px',
}

const h2Style = {
  fontSize: '24px',
  fontWeight: 'bold',
  margin: '0 0 30px',
}

const paragraph = {
  fontSize: '17px',
  lineHeight: '1.5',
  margin: '0 0 20px',
}

const sectionInner = {
  width: '1024px',
}

const mainSection = {
  display: 'flex',
  justifyContent: 'center',
  flexGrow: 1,
  flexShrink: 0,
}

const footerSection = {
  display: 'flex',
  justifyContent: 'center',
  flexShrink: 0,
  padding: "50px 15px 70px",
  backgroundColor: "#192537",
  color: '#fff',
  margin: '20px 0 0px',
}

const info = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}

const infoColumnOne = {
  flexBasis: '60%'
}

const infoColumnTwo = {
  flexBasis: '40%',
  margin: '24px 0 0',
}

const devider = {
  height: '6px',
  width: '87%',
  backgroundColor: '#B09E80',
  margin: '80px auto 55px',
}

const span = {
  display: 'inline-block',
  marginRight: '10px',
}

const sectionFooter = {
  width: '1024px',
  textAlign: 'center',
}



const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <header style={header}>
        <img src={LogoImage} style={logo} alt="Skincare Scandinavia" />
      </header>
      <main style={mainSection}>
        <section style={sectionInner}>
          <section style={info}>
            <article style={infoColumnOne}>
              <h1 style={h1Style}>Välkommen till Skincare Scandinavia!</h1>
              <p style={paragraph}>Jag som driver Skincare Scandinavia heter Caroline Jephson. Jag har varit legitimerad sjuksköterska i över 10 år och är certifierad inom estetiska behandlingar.
</p>
              <p style={paragraph}>För mig är det viktigt att hålla utseendet naturligt och med hjälp av estetiska behandlingar försköna ditt naturliga utseende.</p>
              <p style={paragraph}>Låt din inre sida spegla ditt yttre!</p>
              
              <p style={paragraph}>Du hittar Skincare Scandinavia hos Erica Kliniken i Lomma. Där erbjuder jag tillsammans med mina kollegor ett brett utbud av skönhetsbehandlingar.</p>
              <p style={paragraph}>Följ mig gärna på Instagram: <a href="https://www.instagram.com/skincarescandinavia">Skincare Scandinavia</a>. På Instagram får du mer information om tjänsterna och kan hålla dig uppdaterad om vad som händer löpande.</p>
              <p style={paragraph}>Tveka inte att höra av dig till mig om du har frågor eller funderingar.</p>
              
            </article>
            <article style={infoColumnTwo}>
              <img src={ClinicImage} style={profileImage} alt="Skincare Scandinavia" />
            </article>
          </section>
          <div style={bookingStyle}>
              <a href="https://www.bokadirekt.se/places/skincare-scandinavia-by-caroline-44961" target="_blank"  rel="noreferrer"> <img src="https://foretag.bokadirekt.se/bokatid/BokaTid_Gron_MorkBakgrund_120px.png" alt="Boka tid" border="0" /></a>
            </div>
          <div style={devider}></div>
          <section style={info}>
            <article>
              <h1 style={h2Style}>Öppettider!</h1>
              <p style={paragraph}>Öppettider enligt <a href="https://www.bokadirekt.se/places/skincare-scandinavia-by-caroline-44961" target="_blank"  rel="noreferrer">BokaDirekt</a> eller enligt överenskommelse, var vänlig och kontakta mig så hittar vi en tid.</p>
              <img src={ModeImage1} style={profileImage} alt="Skincare Scandinavia" />
            </article>
          </section>
          <div style={devider}></div>
          {/* <section style={info}>
            <article>
              <h1 style={h1Style}>Behandlingar</h1>
              <table>
                <thead>
                  <tr>
                    <th colspan="3"><p>Tjänster</p></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Obligatorisk konsultation för NY kund - telefon</td>
                    <td>10 minuter</td>
                    <td>kostnadsfri</td>
                  </tr>
                  <tr>
                    <td>Obligatorisk konsultation för NY kund - på plats</td>
                    <td>30 minuter</td>
                    <td>kostnadsfri</td>
                  </tr>
                  <tr>
                    <td>Återbesök </td>
                    <td>30 minuter</td>
                    <td>kostnadsfri</td>
                  </tr>
                </tbody>
              </table>

              <table>
                <thead>
                  <tr>
                    <th colspan="2"><p>Botox</p></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Botox 1 område</td>
                    <td>1500 kr</td>
                  </tr>
                  <tr>
                    <td>Botox 2 områden</td>
                    <td>2000 kr</td>
                  </tr>
                  <tr>
                    <td>Botox 3 områden</td>
                    <td>2500 kr</td>
                  </tr>
                  <tr>
                    <td>Botox - Ögonbrynslyft</td>
                    <td>1000 kr</td>
                  </tr>
                  <tr>
                    <td>Botox - Microbotox 3 områden</td>
                    <td>2500 kr</td>
                  </tr>
                  <tr>
                    <td>Botox - Lipflip</td>
                    <td>1000 kr</td>
                  </tr>
                  <tr>
                    <td>Botox - Bunny lines på näsa</td>
                    <td>1500 kr</td>
                  </tr>
                  <tr>
                    <td>Botox - Gummy smile (tandkött syns)</td>
                    <td>1500 kr</td>
                  </tr>
                  <tr>
                    <td>Botox - Sura mungipor</td>
                    <td>1500 kr</td>
                  </tr>
                  <tr>
                    <td>Botox - Tandgnissling</td>
                    <td>3500 kr</td>
                  </tr>
                  <tr>
                    <td>Botox - Lyft av nästipp</td>
                    <td>1500 kr</td>
                  </tr>
                  <tr>
                    <td>Botox - Lyft av hals, käke (Nefertiti)</td>
                    <td>3500 kr</td>
                  </tr>
                  <tr>
                    <td>Botox - Apelsinhaka</td>
                    <td>1500 kr</td>
                  </tr>
                </tbody>
              </table>

              <table>
                <thead>
                  <tr>
                    <th colspan="2"><p>Fillers</p></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Fillers - Valfritt område 1 ml</td>
                    <td>2800 kr</td>
                  </tr>
                  <tr>
                    <td>Fillers - Valfritt område 2 ml</td>
                    <td>4500 kr</td>
                  </tr>
                  <tr>
                    <td>Fillers - Valfritt område 3 ml</td>
                    <td>6800 kr</td>
                  </tr>
                  <tr>
                    <td>Fillers - Valfritt område 4 ml</td>
                    <td>9200 kr</td>
                  </tr>
                  <tr>
                    <td>Fillers - Läppar 0,5 ml</td>
                    <td>1800 kr</td>
                  </tr>
                  <tr>
                    <td>Fillers - Läppar 1 ml</td>
                    <td>2800 kr</td>
                  </tr>
                  <tr>
                    <td>Fillers - Rökrynkor runt mun</td>
                    <td>2800 kr</td>
                  </tr>
                  <tr>
                    <td>Fillers - Nasolabialveck 1 ml</td>
                    <td>2800 kr</td>
                  </tr>
                  <tr>
                    <td>Fillers - Sura mungipor (marionett linjer) 1 ml</td>
                    <td>2800 kr</td>
                  </tr>
                  <tr>
                    <td>Fillers - Tear trough</td>
                    <td>3200 kr</td>
                  </tr>
                  <tr>
                    <td>Fillers - Kindben 1 ml</td>
                    <td>2800 kr</td>
                  </tr>
                  <tr>
                    <td>Fillers - Kindben 2 ml</td>
                    <td>4500 kr</td>
                  </tr>
                  <tr>
                    <td>Fillers - Käklinje 2 ml</td>
                    <td>4500 kr</td>
                  </tr>
                  <tr>
                    <td>Fillers - Käklinje 3 ml</td>
                    <td>6800 kr</td>
                  </tr>
                  <tr>
                    <td>Fillers - Haka 1 ml</td>
                    <td>2800 kr</td>
                  </tr>
                  <tr>
                    <td>Fillers - Haka 2 ml</td>
                    <td>4500 kr</td>
                  </tr>
                  <tr>
                    <td>Fillers - Borttagning av filler</td>
                    <td>2000 kr</td>
                  </tr>
                </tbody>
              </table>

              <table>
                <thead>
                  <tr>
                    <th colspan="2"><p>Skinbooster</p></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Skinbooster 1 ml - engångsbehandling</td>
                    <td>2800 kr</td>
                  </tr>
                  <tr>
                    <td>Skinbooster 2 ml - engångsbehandling</td>
                    <td>4500 kr</td>
                  </tr>
                  <tr>
                    <td>Skinbooster 3 ml - engångsbehandling</td>
                    <td>6000 kr</td>
                  </tr>
                </tbody>
              </table>

              <table>
                <thead>
                  <tr>
                    <th colspan="2"><p>Trådlyft</p></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Trådlyft - Fullface</td>
                    <td>19000 kr</td>
                  </tr>
                  <tr>
                    <td>Trådlyft - Kinder och Käke</td>
                    <td>12000 kr</td>
                  </tr>
                  <tr>
                    <td>Trådlyft - Hals</td>
                    <td>9500 kr</td>
                  </tr>
                  <tr>
                    <td>Trådlyft - Kinder</td>
                    <td>7900 kr</td>
                  </tr>
                  <tr>
                    <td>Trådlyft - Käke</td>
                    <td>7900 kr</td>
                  </tr>
                </tbody>
              </table>


            </article>
          </section> */}<section style={info}>
            <article>
              <h1 style={h2Style}>Hitta till Skincare Scandinavia!</h1>
              <p style={paragraph}>Adress: Bronsgatan 234 35 Lomma</p>
              <p style={paragraph}>Kliniken ligger med goda förbindelser nära E6 och busshållsplats.</p>
              <p style={paragraph}>Kliniken ligger på markplan i samma hus som Nordic Wellness. </p>
              <p style={paragraph}>Gratis parkering finner du precis utanför kliniken på Mc Donalds parkeringsplats eller på bensinmacken.</p>
              <img src={MapImage} style={mapStyles} alt="Bronsgatan 234 35 Lomma" />
            </article>
          </section>
        </section>
      </main>
      <footer style={footerSection}>
        <section style={sectionFooter}> 
          <span style={span}>Du hittar kliniken på Bronsgatan i Lomma. </span>
          <span style={span}>E-post: <a href="mailto:skincarescandinavia@gmail.com">skincarescandinavia@gmail.com</a></span>
          <span style={span}>Telefon: <a href="tel:0709 20 33 20">0709 20 33 20</a></span>
        </section>
      </footer>
    </main>
  )
}

export default IndexPage
